$(document).ready(function(){

    function grayout(select){
        if (select.val() != '') {
            select.css('color', '#000');
        } else {
            select.css('color', 'rgba(0,0,0,.5)');
        }
    }

    $('.c-select').each(function(){
        var select = $(this).find('.c-select__select');
        select.change(function(){
            grayout(select);
        });
        grayout(select);
    });
});